import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getAuth, connectAuthEmulator, GoogleAuthProvider } from "firebase/auth"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// Firebase web config
const firebaseConfig = {
  apiKey: process.env.FIREBASE_apiKey,
  authDomain: process.env.FIREBASE_authDomain,
  projectId: process.env.FIREBASE_projectId,
  storageBucket: process.env.FIREBASE_storageBucket,
  messagingSenderId: process.env.FIREBASE_messagingSenderId,
  appId: process.env.FIREBASE_appId
};

class Firebase {

  constructor() {
    
    if(typeof window !== "undefined") {
      this.app = initializeApp(firebaseConfig);
      this.auth = getAuth(this.app);

      this.uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/escritorio/mi-biblioteca',
        signInOptions: [ GoogleAuthProvider.PROVIDER_ID ],
      }
    
      if (process.env.NODE_ENV === 'development') {
        if (process.env.FIREBASE_EMULATORS === 'true') {
          console.log('Attching firebase emulators')
      
          if (!getFirestore(this.app)._settingsFrozen) {
            console.log('Attching firestore emulators')
            connectFirestoreEmulator(getFirestore(this.app), '127.0.0.1',5080);
          }
          connectStorageEmulator(getStorage(), "localhost", 9199);
          if (!this.auth.config.emulator) {
            console.log('Attching auth emulators')
            connectAuthEmulator(getAuth(this.app), "http://127.0.0.1:9099", {
              disableWarnings: true,
            });
          }
          connectFunctionsEmulator(getFunctions(this.app, 'europe-west1'), '127.0.0.1', 5201)
        }
      }
  }
  }
}

export default Firebase;
